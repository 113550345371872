<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t("agent.head") }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("agent.head_list") }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input ref="startDate" :date="dateFrom" :label="$t('agent.register_date_from')"
                @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input ref="endDate" :date="dateTo" :label="$t('agent.register_date_to')"
                @setValue="dateTo = $event" />
            </span>
            <v-btn color="primary" class="" @click="searchPartner"> {{ $t("search") }} </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t("search") }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table style="width: 100%" mobile-breakpoint="0" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :page.sync="pagination.page" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50, 100, 200, 300],
            }" :items="members">
            <template v-slot:[`item.bank`]="{ item }">
              <div class="ma-auto" style="width: 24px;height: 24px;aspect-ratio: 1;display: grid;">
                <div v-if="!item.bankCountry">
                  <img v-if="item.bank" alt="bank_img"
                    style="object-fit: contain;height: inherit;width: 100%;"
                    :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
                </div>
                <div v-else>
                  <img v-if="item.bank" alt="bank_img"
                    style="object-fit: contain;height: inherit;width: 100%;"
                    :src="`https://www.ak47.services/akasset-v2/banks/${item.bankCountry}/${item.bank}.webp`" />
                </div>
              </div>
              <span>{{ item.account }}</span>
            </template>
            <template v-slot:[`item.commission`]="{ item }">
              <v-btn color="success" class="white--text text-capitalize" @click="linkTo('agent-commission', item.id)">
                Commission
              </v-btn>
            </template>
            <template v-slot:[`item.members`]="{ item }">
              <v-btn color="primary" class="white--text text-capitalize"
                @click="linkTo('agent-report-member-register', item.id)">
                {{ Number(item.ref).toLocaleString() }} {{ $t('agent.people_per_report') }}
              </v-btn>
            </template>
            <template v-slot:[`item.summary`]="{ item }">
              <v-btn color="warning" class="white--text text-capitalize" @click="linkTo('agent-report-member', item.id)">
                {{ $t("report") }}
              </v-btn>
            </template>
            <template v-slot:[`item.summary_play`]="{ item }">
              <v-btn color="warning" class="white--text text-capitalize"
                @click="linkTo('agent-report-member-total-play', item.id)">
                {{ $t("report") }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
      <staff-show-detail :dialog="showDetail" :username="currentMemberDetail.username"
        :password="currentMemberDetail.password" @setDialog="showDetail = $event" />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import StaffShowDetail from '@/views/staff/StaffShowDetail.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
    StaffShowDetail,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: moment().subtract(3, 'years').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      headers: [
        {
          text: this.$t("agent.tel"),
          value: 'tel',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t("agent.fullname"),
          value: 'name',
          width: '150px',
          sortable: false,
        },
        {
          text: this.$t("agent.bank"),
          value: 'bank',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("agent.register_date"),
          value: 'date',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t("agent.pay_amount"),
          value: 'commission',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("agent.all_user"),
          value: 'members',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("agent.summary_deposit_withdraw"),
          value: 'summary',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("agent.summary_played_user"),
          value: 'summary_play',
          align: 'center',
          sortable: false,
        },
      ],
      members: [],
      loading: false,
      dummy: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      bankSystem: ''
    }
  },
  async created() {
    this.getWebSettingInfo()
    this.addLogPage()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getUsers(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && !this.dummy) await this.getUsers(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && this.dummy) await this.getUsers(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      },
      deep: true,
    },
  },

  methods: {
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'พันธมิตร',
            url: window.location.href,
            detail: 'รายการพันธมิตร',
            admin: userSystem.name ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) { }
    },
    async searchPartner() {
      this.dummy = false
      await this.getUsers(1, this.pagination.rowsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      this.pagination.page = 1
    },
    async getUsers(page, row, time_from, time_to, search) {
      this.loading = true
      this.members = []
      let rs = await this.$store.dispatch('getPartner', {
        page: page,
        rows: row,
        ...(!search ? { page: page } : {}),
        ...(!search ? { rows: row } : {}),
        ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
        ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
        ...(search ? { search: search } : {}),
      })
      this.pagination.totalItems = rs.count
      rs.data.forEach(element => {
        let splitCountry, splitBankCode;
        if (element && element?.bank_code) {
          const code = element.bank_code;
          const checkCountryCode = (code) => {
            const validCodes = ['TH', 'KR', 'LA', 'TW', 'CN', 'KH', 'VN', 'MM'];
            const firstTwoChars = code.substring(0, 2);
            return validCodes.includes(firstTwoChars);
          }

          if (checkCountryCode(code)) {
            splitCountry = code.slice(0, 2);
            splitBankCode = code.slice(2);
          } else {
            splitCountry = this.bankSystem;
            splitBankCode = code;
          }

          element.bankCountry = splitCountry.toLowerCase();
          element.bank = splitBankCode;
        }

        this.members.push({
          id: element.id,
          name: element.name ? element.name : '',
          email: element.email ? element.email : '',
          line: element.line ? element.line : '',
          tel: element.phone ? element.phone : '',
          bank: element.bank,
          bankCountry: element.bankCountry,
          ref: element.registerCount,
          account: element.bank_number,
          date: moment(element.createdAt).format('YY-MM-DD HH:mm'),
        })
      })
      this.loading = false
    },
    showMemberDetail(value) {
      this.currentMemberDetail = value
      this.showDetail = true
    },
    linkTo(name, id) {
      this.$router.push({ name: name, params: { id } })
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      await this.getUsers(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.page = 1
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
