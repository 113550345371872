<template>
  <v-dialog v-model="dialog" width="500px">
    <v-card>
      <v-card-title>
        Username / Password
        <v-spacer />
        <v-btn text icon @click="$emit('setDialog', !dialog)">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span class="d-flex md6">Username : {{ username }}</span>
          </v-col>
          <v-col>
            <span>Password : {{ password }}</span>
          </v-col>
          <v-col>
            <span>Point : {{ point }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="depositWithdraw">
        <v-card>
          <v-row class="px-5">
            <v-col>
              <span class="success--text">{{ $t("txt_deposit") }} : {{ depositWithdraw.deposit }} </span>
            </v-col>
            <v-divider vertical />
            <v-col>
              <span class="error--text"> {{ $t("txt_withdraw") }} : {{ depositWithdraw.withdraw }}</span>
            </v-col>
            <v-divider vertical />
            <v-col>
              <span class="success--text" v-if="(depositWithdraw.total > 0)"> +/- : {{ depositWithdraw.total }}</span>
              <span class="error--text" v-if="(depositWithdraw.total < 0)"> +/- : {{ depositWithdraw.total }}</span>
              <span class="info--text" v-if="(depositWithdraw.total == 0)"> +/- : {{ depositWithdraw.total }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-card width="100%">
          <v-card-actions>
            Turnover (2021-11-21 - 2021-11-21) :
            <v-spacer />
            0
          </v-card-actions>
          <v-card-actions class="py-4 primary">
            <span class="white--text button">MAIN WALLET :</span>
            <v-spacer />
            <span class="white--text">0.00</span>
          </v-card-actions>
          <span v-for="(item, no) in wallets" :key="no">
            <v-card-actions class="py-4"> {{ item.wallet }} : <v-spacer /> {{ item.balance }} </v-card-actions>
            <v-divider />
          </span>
        </v-card>
      </v-card-actions>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="$emit('setDialog', !dialog)">{{ $t('ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    username: {
      type: String,
      default: 'UKOFA9871870',
    },
    password: {
      type: String,
      default: 'AAbc7256',
    },
    point: {
      type: Number,
      default: 0,
    },
    deposit: {
      type: Number,
      default: 0,
    },
    withdraw: {
      type: Number,
      default: 0,
    },
    wallets: {
      type: Array,
      default: () => [
        // {
        //   name: 'กีฬา AK2',
        //   value: '0.00',
        // },
        // {
        //   name: 'WM บ่อนคาสิโน',
        //   value: '0.00',
        // },
        // {
        //   name: 'Spade Gaming',
        //   value: '0.00',
        // },
        // {
        //   name: 'XE88',
        //   value: '0.00',
        // },
        // {
        //   name: 'WANG GAMING',
        //   value: '0.00',
        // }
      ],
    },
    depositWithdraw: {
      type: Object,
      default: null,
    },
  },
}
</script>
